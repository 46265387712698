import React from "react"

import { Row, Col } from "react-bootstrap"

const ContentPage = () => (
  <>
    <div className="p-5">
      <Row className="mt-5 no-gutters">
        <Col>
          <h3 className="text-left">Presentazione</h3>
          <hr />

          <p className="_02 paragraph">
            In un quadro socio-politico-economico mondiale caratterizzato da
            crescente malessere sociale, instabilità politica e crisi
            finanziaria, si afferma sempre più la volontà di sviluppare
            tecnologie che assicurino riduzione nelle emissioni di CO2 (così
            come stabilito in ambito internazionale dal protocollo di Kyoto),
            risparmio nell'utilizzo dei combustibili fossili (la cui
            disponibilità mondiale è limitata nel tempo) e remunerazione per il
            cliente e per il consumatore finale.
            <br />
            <br />
            In questo panorama, Leonardo srl nasce e si afferma tra il 2005 e il
            2006 con l'intento di offrire le migliori soluzioni tecnologiche
            integrate basate sull'utilizzo di fonti di energia rinnovabili
            legate, quindi, al risparmio energetico e al contenimento dei
            fenomeni di inquinamento ambientale.
            <br />
            <br />
            La nostra società prende il nome dal grande scienziato, artista e
            pittore italiano.
            <br />
            Uomo d'ingegno e talento universale del Rinascimento italiano,
            incarnò in pieno lo spirito della sua epoca, portandolo alle
            maggiori forme di espressione nei più disparati campi dell'arte e
            della conoscenza. Fu pittore, disegnatore, scultore, architetto,
            ingegnere, scenografo, anatomista, letterato, musicista e inventore.
            <br />
            <br />
            Pertanto Leonardo srl è una società di engineering fortemente
            orientata alle problematiche ambientali e allo sviluppo di nuove
            soluzioni sempre rivolte alle esigenze del cliente, grazie al team
            di persone che possiede un consolidato Know How in ambito
            tecnologico.
            <br />
            <br />
            Leonardo Srl si pone nella logica di una politica industriale basata
            sulla qualificazione dei sistemi innovativi integrati con
            l’obiettivo di raggiungere una posizione competitiva nel mercato
            nazionale ed internazionale. Leonardo Srl è in grado di rispondere
            ai requisiti tecnici ed agli standards produttivi ai massimi
            livelli, interpretando nel segno "moderno", la ricerca di
            apparecchiature elettriche ed elettroniche e lo sviluppo di
            soluzioni orientate al soddisfacimento del cliente e alla
            sostenibilità sociale e ambientale.
            <br />
            <br />
            L'azienda ha definito il rapporto qualità/prezzo dei suoi prodotti
            come fattore critico di successo consentendole di competere con le
            principali aziende del settore.Gli interlocutori di Leonardo srl
            sono fondi d'investimento, pubbliche amministrazioni, aziende e
            privati che intendono avvalersi della nostra professionalità e del
            nostro know how in ambito ingegneristico.
            <br />
            <br />
            Il servizio “Chiavi in mano” ovvero “Engineering Procurement &amp;
            Construction (EPC)” che Leonardo Srl eroga, comprende le fasi di
            seguito dettagliate.
            <br />
            <br />
          </p>
          <ul>
            <li>
              Fornitura delle parti, dei componenti e del kit dell'impianto
              tecnologico.
            </li>
            <li>
              Assistenza tecnico amministrativa per gli adempimenti previsti
              dalla legge “Conto Energia”, per le pratiche ENEL/GSE e consulenza
              finanziaria per il reperimento delle risorse monetarie necessarie
              per la realizzazione dell’opera mediante l’accesso a specifici ed
              idonei prodotti finanziari convenzionati.
            </li>
            <li>
              Progettazione e realizzazione dell’opera che comprende la
              progettazione esecutiva e di dettaglio, direzione dei lavori,
              richieste di autorizzazioni necessarie alla realizzazione
              dell’impianto, collaudo elettrico strutturale, emissione ordini di
              fornitura e stipula contratti di appalto, gestione del progetto e
              controllo dei costi preventivati.
            </li>
            <li>
              Installazione e collaudo che comprende la realizzazione completa a
              regola d’arte, esecuzione dei test funzionali e prestazionali,
              messa in esercizio e collaudo dell’impianti.
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  </>
)

export default ContentPage
